<template>
  <div id="isLocation">
    <div class="locationBackgroud">
      <div style="color: #999999;padding-left:5vw;">
        您所在的位置：
      </div>
      <div>{{ barBtn }}{{ addressLi | isAddress }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "isLocation",
  mounted() {
  },
  data() {
    return {};
  },
  props: {
    barBtn: {
      type: String,
    },
    addressLi: {
      type: String,
    },
  },
  filters: {
    isAddress(val){
      if(val == ''){
        return val = '';
      }else{
        return val = '>'+val;
      }
    }
  },
  methods: {},
};
</script>

<style scoped lang="scss">
.locationBackgroud {
  display: flex;
  height: 2vh;
  background-color: #f5f5f5;
  font-size: 0.9vw;
  padding: 1vh 9vw;
  align-items: center;
}
</style>
