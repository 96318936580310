<!--企业文化-->
<template>
    <div id="corporateCulture">
        <div v-if="!isMobile">
            <div class="list">
                <div>
                    <div class="list_li">
                        <div>
                            <img src="../../../assets/images/zhuanye.jpg" alt="" />
                        </div>
                        <div>
                            <div>专业</div>
                            <div>专业技术服务，满足客户需求</div>
                        </div>
                    </div>
                </div>
                <div>
                    <div class="list_li">
                        <div>
                            <img src="../../../assets/images/gaoxiao.jpg" alt="" />
                        </div>
                        <div>
                            <div>高效</div>
                            <div>十年互联网大数据技术，为系统保驾护航</div>
                        </div>
                    </div>
                </div>
                <div>
                    <div class="list_li">
                        <div>
                            <img src="../../../assets/images/chengxin.jpg" alt="" />
                        </div>
                        <div>
                            <div>诚信</div>
                            <div>以诚信服务为原则，专业售后服务</div>
                        </div>
                    </div>
                </div>
                <div>
                    <div class="list_li">
                        <div>
                            <img src="../../../assets/images/jiangxin.jpg" alt="" />
                        </div>
                        <div>
                            <div>匠心</div>
                            <div>坚持匠心精神，铸就高品质体验</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="elegantDemeanor">
                <div></div>
                <div>公司团结风采</div>
                <div></div>
            </div>
            <div class="_list">
                <div class="left_img">
                    <img src="../../../assets/images/culture1.jpg" alt="" />
                </div>
                <div class="right_img">
                    <div class="imgUi">
                         <div>
                            <img src="../../../assets/images/culture2.jpg" alt="" />
                        </div>
                        <div>
                            <img src="../../../assets/images/culture3.jpg" alt="" />
                        </div>
                        <div>
                            <img src="../../../assets/images/culture4.jpg" alt="" />
                        </div>
                    </div>
                   <div class="imgUi">
                        <div>
                            <img src="../../../assets/images/culture6.jpg" alt="" />
                        </div>
                        <div>
                            <img src="../../../assets/images/culture7.jpg" alt="" />
                        </div>
                        <div>
                            <img src="../../../assets/images/culture5.jpg" alt="" />
                        </div>
                   </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  name: "corporateCulture",
  mounted() {
  },
  data() {
    return {
        isMobile:false
    };
  },
  methods: {
  }
};
</script>

<style scoped lang="scss">
#corporateCulture{
    padding: 0 11vw 0 10vw;
}
.list{
    display:flex;
    flex-direction:row;
    justify-content: space-between;
    flex-wrap:wrap;
    div{
        width:20%;
        padding:1vh 1vw;
        .list_li{
            background-color:#F8F8FA;
            padding:0 0.5vw;
            width:100%;
            display:flex;
            div:nth-child(1){
                width:30%;
                img{
                    width:100%;
                }
            }
            div:nth-child(2){
                flex:1;
                padding:0;
                div{
                    padding:0;
                    width:100%;
                }
                div:nth-child(1){
                    display:flex;
                    align-items:center;
                    font-weight:bold;
                    font-size:1em;
                    height:40%;
                }
                div:nth-child(2){
                    font-size:0.7em;
                    height:60%;
                }
            }
        }
    }
}
._list{
    display:flex;
    .left_img{
        width:25%;
        padding:0.5vw;
        img{
            width:100%;
        }
    }
    .right_img{
        flex:1;
        .imgUi{
            height:50%;
            display:flex;
             div{
                padding: 0.5vw;
                flex:1;
                width:31%;

                img{
                    width:100%;
                    height:100%;
                }
            }
        }
       
    }
}
.honor_img{
    padding:2vh 5vw;
    display:flex;
    flex-direction:row;
    justify-content: space-between;
    flex-wrap:wrap;
     div{
        width:46%;
        height:20vh;
        padding:1vh 1vw;
        img{
            width:100%;
            height:100%;
        }
    }
}
._honor_img{
    padding:2vh 5vw;
    img{
        width:100%;
        height:100%;
    }
}
.elegantDemeanor{
    display:flex;
    justify-content:center;
    align-items:center;
    div:nth-child(1){
        background-color:#D8D8D8;
        height:1px;
        width:1.5vw;
    }
    div:nth-child(2){
        font-weight:bold;
        font-size:1rem;
        padding:0 1vw;
    }
    div:nth-child(3){
        background-color:#D8D8D8;
        height:1px;
        width:1.5vw;

    }
}
</style>