<!--公司优势-->
<template>
    <div id="advantage">
        <div class="advantage_content">
            <div class="flex_align_center" style="flex:1;">
                <div class="advantageBox">
                    <p class="p_title">
                    无感化智能训练研究先行企业
                    </p>
                    <p class="p_content">
                    公司运用云服务、大数据、物联网等高新智能技术，综合集成各类通信、定位、传感、控制设备，融合军事学、心理学等专业知识，与某集团军联合研发了《军事训练管理云平台》，该系统是集计划、实施、考评、监察、保障为一体的智能训练体系，为训练分析、短板补差提供有力依据和参考，促进训练增益，可广泛适用于各军兵种部队。
                    </p>
                </div>
                <div class="flex_align_center">
                    <canvas id="line_left">
                    </canvas>
                </div>
            </div>
            <div class="mlf_advantage" style="flex:1;">
                <img src="../../../assets/images/bigNotes.png" alt="" />
            </div>
            <div style="display:flex;flex:1;">
                <div class="items_flex">
                    <div class="items_li">
                        <canvas id="line_right_top">
                    </canvas>
                    </div>
                    <div class="items_li">
                        <canvas id="line_right_bottom">
                        </canvas>
                    </div>
                </div>
                <div class="items_flex">
                    <div class="items_li">
                        <div class="advantageBox">
                            <p class="p_title">
                                致力于智能打靶场景建设运用
                            </p>
                            <p class="p_content">
                                公司引进智能打靶技术，构设逼真战场环境，引进集光学、电子、机械、通信、编程为一体的新型智能打靶训练器材，研发实兵信息化战术训练系统，为军警、企事业单位、军事爱好者提供服务。
                            </p>
                        </div>
                    </div>
                    <div class="items_li">
                        <div class="advantageBox">
                            <p class="p_title">
                                无感化智能训练研究先行企业
                            </p>
                            <p class="p_content">
                                公司运用云服务、大数据、物联网等高新智能技术，综合集成各类通信、定位、传感、控制设备，融合军事学、心理学等专业知识，与某集团军联合研发了《军事训练管理云平台》，该系统是集计划、实施、考评、监察、保障为一体的智能训练体系，为训练分析、短板补差提供有力依据和参考，促进训练增益，可广泛适用于各军兵种部队。
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
        
    </div>
</template>

<script>
export default {
  name: "advantage",
  mounted() {
      this.line_left();
      this.line_right_top();
      this.line_right_bottom();
  },
  data() {
    return {
        isMobile:false
    };
  },
  methods: {
      line_left(){
        var c=document.getElementById("line_left");
        var ctx=c.getContext("2d");
        ctx.moveTo(0,100);
        ctx.lineTo(200,100);
        ctx.strokeStyle = 'rgb(66, 184, 236)';
        ctx.lineCap = "round";
        ctx.stroke();
        ctx.beginPath();//开始绘制
        ctx.arc(200,100,10,0,2*Math.PI);//arc 的意思是“弧”
        ctx.fillStyle="rgb(66, 184, 236)";//设置填充颜色
        ctx.fill();//开始填充
        ctx.strokeStyle = 'rgb(66, 184, 236)';
        ctx.stroke();
      },
      line_right_top(){
        var c=document.getElementById("line_right_top");
        var ctx1=c.getContext("2d");
        ctx1.moveTo(10,90);
        ctx1.lineTo(250,10);
        ctx1.strokeStyle = 'rgb(66, 184, 236)';
        ctx1.lineWidth = 5;
        ctx1.stroke();
        var ctx2=c.getContext("2d");
        ctx2.beginPath();//开始绘制
        ctx2.arc(15,90,10,0,2*Math.PI);//arc 的意思是“弧”
        ctx2.fillStyle="rgb(66, 184, 236)";//设置填充颜色
        ctx2.fill();//开始填充
        ctx2.strokeStyle = 'rgb(66, 184, 236)';
        ctx2.stroke();
      },
      line_right_bottom(){
        var c=document.getElementById("line_right_bottom");
        var ctx1=c.getContext("2d");
        ctx1.moveTo(15,15);
        ctx1.lineTo(250,50);
        ctx1.strokeStyle = 'rgb(66, 184, 236)';
        ctx1.lineWidth = 5;
        ctx1.stroke();
        var ctx2=c.getContext("2d");
        ctx2.beginPath();//开始绘制
        ctx2.arc(15,15,10,0,2*Math.PI);//arc 的意思是“弧”
        ctx2.fillStyle="rgb(66, 184, 236)";//设置填充颜色
        ctx2.fill();//开始填充
        ctx2.strokeStyle = 'rgb(66, 184, 236)';
        ctx2.stroke();
      },
  }
};
</script>

<style scoped lang="scss">
    .mlf_advantage{
        display:flex;
        align-items:center;
        img{
            width:100%;
        }
    }
    #advantage{
        padding: 0 11vw 0 10vw;
    }
    .advantage_content{
        display:flex;
    }
    .advantageBox{
        background-image:url('../../../assets/images/box.png');
        background-size:100% 100%;
        padding:1vh 1vw;
    }
    #line_left{
        width:100px;
    }
    #line_right_top{
        width:100px;
    }
    #line_right_bottom{
        width:100px;
    }
    .flex_align_center{
        display:flex;
        align-items:center;
    }
    .p_title{
        color:#004988;
        font-size:1.2rem;
        font-weight:bold;
    }
    .p_content{
        font-size:0.9rem;
    }
    .items_flex{
        display:flex;
        flex-direction:column;
        justify-content:space-between;
        .items_li{
            flex:1;
            display:flex;
            align-items:center;
        }
    }
</style>