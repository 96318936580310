<!--人才簡介-->
<template>
  <div id="recruit">
    <div class="department">
      <div class="departBtnBox">
        <div
          :class="isdepart == 'develop' ? 'isDepart' : ''"
          class="departBtn"
          @click="isdepart = 'develop'"
        >
          开发部
        </div>
        <div
          :class="isdepart == 'project' ? 'isDepart' : ''"
          class="departBtn"
          @click="isdepart = 'project'"
        >
          项目部
        </div>
        <div
          :class="isdepart == 'businessAffairs' ? 'isDepart' : ''"
          class="departBtn"
          @click="isdepart = 'businessAffairs'"
        >
          商务部
        </div>
      </div>
    </div>
    <div class="postList">
      <div class="developList">
        <div
          class="develop_li"
          v-for="(item, k) in departList"
          :key="k"
          v-show="item.isdepart == isdepart"
        >
          <div>
            <div>
              {{ item.postName }} 
            </div>
          </div>
          <div>
            <p v-for="(items, keys) in item.ask" :key="keys">
              {{ items }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "recruit",
  mounted() {},
  data() {
    return {
      isdepart: "develop",
      departList: [
        {
          postName: "中高级前端工程师",
          isdepart: "develop",
          ask: [
            "本科及以上学历，计算机相关专业，三年以上的前端开发经验；",
            "熟练使用Vue.js，掌握其他mvvm框架（react,angular）也可考虑；",
            "精通HTML、CSS等网页制作技术，熟悉页面架构和布局，熟练掌握DIV+CSS制作网页的技巧；",
            "能独立开发一些前端小组件；",
            "了解前端工程化流程，懂得使用gulp或webpack等，了解使用过nodejs，接触过websocket；",
            "接触过一些简单的后端开发（php尤佳），了解后端工作的原理,懂一些基本的数据库知识及sql；",
            "逻辑思维缜密, 代码冗余率低，有定制化平台开发经验者优先。",
          ],
        },
        {
          postName: "前端工程师",
          isdepart: "develop",
          ask: [
            "实现网站前端html/css，js动态效果（移动端和pc端）；",
            "优化前端代码。",
            "本科以上学历，软件工程、计算机相关专业，有与后台配合经验；",
            "精通HTML、CSS等网页制作技术，熟悉页面架构和布局，熟练掌握DIV+CSS制作网页的技巧；",
            "熟练使用JS、jquery；",
            "熟悉主流浏览器（IE、FF、Chrome、safari）兼容性问题的解决方案；",
            "具有审美观，能有自己的用户体验优化思想，不放过每个细节，力求完美，有良好的代码习惯（命名规范，注释，拒绝代码冗余）；",
            "了解前端工程化流程，懂得使用gulp或webpack等，了解使用过nodejs；",
            "掌握react,vue,angularjs之一者尤佳。",
          ],
        },
        {
          postName: "PHP开发工程师",
          isdepart: "develop",
          ask: [
            "熟悉PHP的编写，至少熟练使用一门MVC框架；",
            "熟练掌握常用Sql语句写法（增删查改分组汇总等）；",
            "了解HTML、JavaScript、CSS基本编写，熟练掌握jquery者优先；",
            "了解Window/Linux之上的Apache等Web服务器的架设配置；",
            "具有良好的编码习惯（结构清晰、命名规范、注释严谨），有软件设计师，数据库系统工程师，系统集成项目管理工程师等软考证书者优先。",
          ],
        },
        {
          postName: "安卓工程师",
          isdepart: "develop",
          ask: [
            "根据产品功能需求设计并完成软件实现；",
            "负责android系统产品需求的分析、定位与抽象；",
            "负责基于android系统平台进行手机软件的设计、开发、调试、移植；",
            "负责android系统软件产品的维护和BUG修正。",
            "本科以上学历，软件工程、计算机相关专业，英语四级及以上，熟悉android系统平台的开发；",
            "具有良好的编码习惯（结构清晰、命名规范、注释严谨）；android系统应用已有发布到市场或AppStore、应用项目经验者优先。有蓝牙等相关开发经验者优先考虑。",
          ],
        },
        {
          postName: "Java工程师",
          isdepart: "develop",
          ask: [
            "根据项目规划，负责项目子模块研发工作；",
            "独立负责处理和解决线上产品出现的一般问题；",
            "对开发过程出现的技术难点进行攻关及非产品核心技术的难点的预研；",
            "项目的开发和上线工作。",
            "本科以上学历，计算机或相关专业，3年以上Java开发及Web开发经验；",
            "熟练java语言，熟练运用多种软件设计模式；",
            "熟练掌握J2EE主流架构Struts2、Spring、Hibernate技术等；",
            "熟悉HTML、java script、ajax、jsp、servlet、struts、jstl；",
            "熟悉基于tomcat、Weblogic、JBoss、IBM Was等应用服务器的开发部署；",
            "熟悉Oralce/MS SQL等数据库开发。",
          ],
        },
        {
          postName: "Java工程师",
          isdepart: "develop",
          ask: [
            "根据项目规划，负责项目子模块研发工作；",
            "独立负责处理和解决线上产品出现的一般问题；",
            "对开发过程出现的技术难点进行攻关及非产品核心技术的难点的预研；",
            "项目的开发和上线工作。",
            "本科以上学历，计算机或相关专业，3年以上Java开发及Web开发经验；",
            "熟练java语言，熟练运用多种软件设计模式；",
            "熟练掌握J2EE主流架构Struts2、Spring、Hibernate技术等；",
            "熟悉HTML、java script、ajax、jsp、servlet、struts、jstl；",
            "熟悉基于tomcat、Weblogic、JBoss、IBM Was等应用服务器的开发部署；",
            "熟悉Oralce/MS SQL等数据库开发。",
          ],
        },
        {
          postName: "实施工程师",
          isdepart: "project",
          ask: [
            "负责客户现场的需求调研和需求分析工作，按照项目要求完成相应的需求调研报告、需求说明书；",
            "编制实施方案、实施计划，并按计划推进项目实施工作，按时按质交付实施成果；",
            "进行期初数据和基础数据准备，参与应用测试，书写测试计划和测试报告；",
            "编写用户手册和培训文档，对用户进行系统的培训与操作指导；",
            "及时记录并解决实施中遇到的各种问题，记录并反馈用户在使用过程中遇到的问题；",
            "维护良好的客户关系。",
            "本科及以上学历，计算机、企业管理相关专业；",
            "具有扎实的计算机基础知识，对ERP、企业管理（例如OA、供应链等其中一项）有一定认识；",
            "逻辑思维清晰、有较强的自学能力，有吃苦耐劳的精神；",
            "具有较强的沟通能力和客户服务意识；",
            "能适应公司出差制度；",
            "有实施相关工作经验、对网络有一定基础者优先考虑。有系统集成项目管理工程师，软件测试工程师等软考证书者优先。欢迎有意向的应届生投递简历！",
          ],
        },
        {
          postName: "产品专员",
          isdepart: "project",
          ask: [
            "根据公司的战略规划，分析行业内外相关产品，完成竞品分析，并提出需求分析报告和实现计划要求；",
            "梳理产品功能，研究用户习惯，挖掘用户需求，分析使用场景等，细化产品设计，并负责产品原型的设计工作，PRD文档的撰写；",
            "跟进UI设计，确保产品效果图符合产品需求文档的要求；",
            "负责与研发、测试等部门沟通协调，确保各个协作部门对产品充分的理解；",
            "根据公司自身情况做好产品版本迭代规划工作。",
            "良好自学能力，热爱并关注行业发展动态；",
            "本科及以上学历，Xmind/MindManager、Visio、Axure、office等软件熟练使用；",
            "思维敏捷，性格开朗，责任感强，工作积极主动，具备较强的学习与创新意识；",
            "有产品经理或产品策划相关经验，有成功项目实践案例者优先考虑。欢迎优秀应届生投递！",
          ],
        },
        {
          postName: "商务专员",
          isdepart: "businessAffairs",
          ask: [
            "公司拟参与项目的技术交流、方案编写、标书的编制、及技术文档的整合、验证、持续改进等工作；",
            "配合做好用户沟通、技术协调等工作；",
            "拟定商务合同条款，跟踪签订合同；",
            "与技术部门协调，跟踪确保产品供应周期；",
            "参与项目标书的制定,协助做好项目的投标准备工作，确保投标文件准确、合理、具竞争性，确保投标文件按时投递。",
            "具备良好的文案编写能力、客户需求分析能力和资源整合能力；",
            "具有发散型创意思维，善于独立思考，善于沟通，并具备良好的团队合作意识；",
            "具有扎实的文字功底、良好的沟通技巧，能够独立撰写各种方案书；",
            "掌握Axure原型设计、xmind/mindmanager、visio等工具优先。",
          ],
        },
      ],
    };
  },
  methods: {},
};
</script>

<style scoped lang="scss">
#recruit {
  padding: 0 11vw 0 10vw;
}

.department {
  display: flex;
  justify-content: flex-end;
  .departBtnBox {
    display: flex;
    .departBtn {
      padding: 0.5vh 1vw;
      border: 1px solid #015293;
      color: #015293;
    }
    .isDepart {
      background-color: #015293;
      color: white;
    }
  }
}
.develop_li {
  padding: 1vh 1vw;
  display: flex;
  div:nth-child(1) {
    width: 30%;
    font-size: 1.2rem;
    font-weight: bold;
    padding-top: 1vh;
    div{
      width:100%;
      padding-left: 8vh;  
    }
  }
  div:nth-child(2) {
    flex: 1;
    font-size: 1rem;
  }
}
</style>
