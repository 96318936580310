<!--关于麦乐峰-->
<template>
  <div id="about">
    <isLocation :barBtn="location" :addressLi="address"></isLocation>
    <headTitle
      barBtn="关于麦乐峰"
      :isMobile="$store.state.isMobile"
      :isActive="isActive"
      @editType="getPage"
    ></headTitle>
    <bigEgvent v-show="isActive == 'bigNotes'" :isMobile="$store.state.isMobile"></bigEgvent>
    <honor v-show="isActive == 'honor'"></honor>
    <corporateCulture
      v-show="isActive == 'corporateCulture'"
    ></corporateCulture>
    <recruit v-show="isActive == 'recruit'"></recruit>
    <introduce
      v-show="isActive == 'introduce'"
      :isMobile="$store.state.isMobile"
    ></introduce>
    <advantage v-show="isActive == 'advantage'"></advantage>
  </div>
</template>

<script>
import isLocation from "@/components/isLocation/index";
import headTitle from "@/components/headTitle/index";
import introduce from "./components/introduce";
import bigEgvent from "./components/bigEgvent";
import honor from "./components/honor";
import corporateCulture from "./components/corporateCulture";
import recruit from "./components/recruit";
import advantage from "./components/advantage";
export default {
  name: "about",
  mounted() {},
  components: {
    isLocation,
    headTitle,
    bigEgvent,
    honor,
    corporateCulture,
    recruit,
    introduce,
    advantage,
  },
  data() {
    return {
      location: "关于我们",
      isActive: "introduce",
      address: "公司简介",
    };
  },
  methods: {
    getPage(val) {
      this.isActive = val;
      switch (val) {
        case "introduce":
          this.address = "公司简介";
          break;
        case "honor":
          this.address = "荣誉资质";
          break;
        case "corporateCulture":
          this.address = "企业文化";
          break;
        case "advantage":
          this.address = "公司优势";
          break;
        case "bigNotes":
          this.address = "大事记";
          break;
        case "recruit":
          this.address = "人才招聘";
          break;
        default:
          this.address = "";
          break;
      }
    },
  },
};
</script>

<style scoped lang="scss"></style>
