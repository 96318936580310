<!--顶部关于我们-->
<template>
  <div id="headTitle">
    <div
      :class="isMobile ? '_home-news-information' : 'home-news-information'"
    >
      <div :class="isMobile ? '_home-items-title' : 'home-items-title'">
        <div>{{barBtn|titleName}}</div>
        <div><img src="../../assets/homePage/title-icon.png" alt="" /></div>
        <div>PRODUCT CENTER</div>
        <hr />
        <div class="more_news" v-show="barBtn == item.title_name" v-for="(item,k) in title_list" :key="k">
          <span
            v-for="(items,index) in item.title_li" :key="index"
            class="more_text"
            :class="isActive == items.sign ? 'isActive' : ''"
            @click="editType(items.sign)"
            >
            {{items.title}}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "headTitle",
  data() {
    return {
      title_list:[
        {
          title_name:'关于麦乐峰',
          title_li:[
            {
              title:'公司简介',
              sign:'introduce',
            },
            {
              title:'荣誉资质',
              sign:'honor',
            },
            {
              title:'企业文化',
              sign:'corporateCulture',
            },
            {
              title:'公司优势',
              sign:'advantage',
            },
            {
              title:'大事记',
              sign:'bigNotes',
            },{
              title:'人才招聘',
              sign:'recruit',
            }
          ]
        },{
          title_name:'解决方案',
          title_li:[
            {
              title:'智慧军营',
              sign:'wisdomBarracks',
            },
            {
              title:'军事训练',
              sign:'militaryTraining',
            },
            {
              title:'强军主题',
              sign:'armyTheme',
            },
            {
              title:'心理作战',
              sign:'psychologicalWarfare',
            },
            {
              title:'模拟仿真',
              sign:'simulation',
            },{
              title:'战场救援',
              sign:'battlefieldRescue',
            },{
              title:'军事大数据',
              sign:'militaryBigdata',
            },{
              title:'训练指导',
              sign:'trainingGuidance',
            }
          ]
        }
      ]
    };
  },
  filters: {
    titleName(val){
      if(val == '关于麦乐峰'){
        return val = '关于我们';
      }else{
        return val;
      }
    },
  },
  props: {
    isMobile: {
      type: Boolean,
      default: true,
    },
    barBtn:{
      type: String,
    },
    newsList: {
      type: Array,
    },
    isActive:{
      type: String,
    }
  },
  methods: {
    editType(val) {
      this.$emit("editType", val);
    },
  },
};
</script>

<style scoped lang="scss">
.more_text {
  padding: 0.5vh 0.5vw;
}
._home-items-title {
  position: relative;
  height: 180px;

  > div:nth-child(1) {
    font-size: 2.3rem;
    font-weight: bold;
    position: absolute;
    top: 1.5vh;
    left: 0;
    z-index: 50;
  }

  > div:nth-child(2) {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 49;

    > img {
      width: 280px;
    }
  }

  > div:nth-child(3) {
    position: absolute;
    top: 4vh;
    left: 13%;
    color: rgb(204, 204, 204);
    font-weight: bold;
    letter-spacing: 3px;
  }

  > hr {
    position: absolute;
    right: 0;
    width: calc(100% - 14rem);
    top: 3vh;
    color: rgb(204, 204, 204);
  }

  .more_news {
    font-size: 1.5rem;
    position: absolute;
    right: 0;
    top: 1vh;
    color: #909399;
    z-index:50;
  }
}

.home-items-title {
  position: relative;
  height: 15vh;

  > div:nth-child(1) {
    font-size: 2.1rem;
    font-weight: bold;
    position: absolute;
    top: 2.5vh;
    left: 5%;
    z-index: 50;
  }

  > div:nth-child(2) {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 49;

    > img {
      width: 14rem;
    }
  }

  > div:nth-child(3) {
    position: absolute;
    top: 8vh;
    left: 7%;
    color: rgb(204, 204, 204);
    font-weight: bold;
    letter-spacing: 3px;
  }

  > hr {
    position: absolute;
    right: 0;
    width: calc(100% - 14rem);
    top: 6vh;
    color: rgb(204, 204, 204);
  }

  .more_news {
    position: absolute;
    right: 0;
    top: 3.5vh;
    color: #909399;
    z-index:50;
  }
}

._home-news-information {
  padding: 45px 60px;

  .home-news-information-item {
    padding-bottom: 45px;
    margin-left: 3vw;
    width: calc(100% - 3vw);
    border-bottom: 1px dotted rgb(225, 225, 225);

    > div {
      display: inline-block;
      vertical-align: middle;

      > div:nth-child(1) {
        display: inline-block;
        width: 25vw;
        height: 200px;
        overflow: hidden;
        > img {
          width: 100%;
          height: 100%;
          object-position: center;
          object-fit: cover;
        }
      }

      > div:nth-child(2) {
        vertical-align: top;
        display: inline-block;
        width: calc(100% - 25vw);
        position: relative;
        height: 200px;

        > div:nth-child(1) {
          margin: 10px;
          font-size: 2rem;
          font-family: "黑体";
          font-weight: bold;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 1;
          overflow: hidden;
        }

        > div:nth-child(2) {
          font-size: 1.8rem;
          padding: 10px;
          line-height: 40px;
        }

        > div:nth-child(3) {
          font-size: 1.8rem;
          padding: 10px;
          color: rgb(102, 102, 102);
          position: absolute;
          bottom: 0;
        }
      }
    }
  }
}

.home-news-information {
  padding: 0 11vw 0 10vw;

  .home-news-information-item {
    padding: 1.5vh 0;
    margin-left: 3vw;
    width: calc(100% - 3vw);
    border-bottom: 1px dotted rgb(225, 225, 225);

    > div:first-child {
      width: 80%;
      display: inline-block;
      vertical-align: middle;

      > div:nth-child(1) {
        display: inline-block;
        width: 80px;
        height: 80px;
        overflow: hidden;

        > img {
          width: 100%;
          height: 100%;
          object-position: center;
          object-fit: cover;
        }
      }

      > div:nth-child(2) {
        vertical-align: top;
        display: inline-block;

        > div:first-child {
          padding: 5px;
          font-size: 1.3rem;
          font-family: "黑体";
          font-weight: bold;
        }

        > div:last-child {
          padding: 5px;
          color: rgb(102, 102, 102);
        }
      }
    }

    > div:last-child {
      width: 20%;
      display: inline-block;
      text-align: right;
    }
  }
}
.isActive {
  background-color: #015293;
  color: #ffffff;
}
</style>
