<!--大事记-->
<template>
    <div class="home-news-information-list" id="bigEgvent">
        <div class="bigNotes_content">
          <img src="../../../assets/images/mobileBigNotes.jpg" alt="" v-if="isMobile"/>
          <img src="../../../assets/images/bigNotes.jpg" v-else alt="" />
        </div>
    </div>
</template>

<script>
export default {
  name: "bigEgvent",
  mounted() {

  },
  data() {
    return {

    };
  },
  props: {
    isMobile: {
      type: Boolean,
      default: true
    },
  },
};
</script>

<style scoped lang="scss">
.bigNotes_content {
  width: 100%;
  img {
    width: 100%;
    height: 100%;
  }
}

</style>
