<!--公司简介-->
<template>
  <div id="introduce">
    <div class="introduce_content">
      <div>
        <img src="../../../assets/images/environment.png" alt="" />
      </div>
      <div>
        <div :style="isMobile ? 'font-size: 3.3vw;':'font-size: 4.8vw;'">
            <p>
                MLF
            </p>
            <p>
                WORLDGROUP
            </p>
        </div>
        <div>
          <p>
            麦乐峰（厦门）智能科技有限公司（以下简称“麦乐峰”）成立于2011年4月19日，注册资本5000万人民币，为福建省海峡科创板挂牌企业（公司简称：麦乐峰，股权代码：160001），是一家以云服务为基础、以信息技术为驱动，专注军工、体育、健康、医疗等多产业发展的国家级高新技术企业，具备军工企业相关资质，具有智能穿戴设备研发、生产能力，有丰富的云端研发、大数据解析和OA大系统开发经验。当前军工业务板块已涵盖智慧军营、军事训练、强军主题、心理作战、模拟仿真、战场救援、军事大数据、训练指导等八大类。
          </p>
          <p>
            目前，公司已获得国家级高新技术企业认证、厦门市双软企业认证、军工企业相关资质，并获得48项军工、心理、体育、管理类软件著作权，10项实用新型发明专利，并通过5项软件开发及信息集成类ISO认证、CMMI三级评估、企业信用AAA等级认证。
          </p>
        </div>
      </div>
    </div>
    <!-- <div>
      <headTitle
        barBtn="公司环境"
        :isMobile="isMobile"
      ></headTitle>
      <div class="environment">
        <div>
          <img src="../../../assets/images/companyEnvironment/environment1.jpg" alt="" />
        </div>
        <div>
          <img src="../../../assets/images/companyEnvironment/environment2.jpg" alt="" />
        </div>
        <div>
          <img src="../../../assets/images/companyEnvironment/environment3.jpg" alt="" />
        </div>
        <div>
          <img src="../../../assets/images/companyEnvironment/environment4.jpg" alt="" />
        </div>
        <div>
          <img src="../../../assets/images/companyEnvironment/environment5.jpg" alt="" />
        </div>
        <div>
          <img src="../../../assets/images/companyEnvironment/environment6.jpg" alt="" />
        </div>
      </div>
    </div> -->
  </div>
</template>

<script>
// import headTitle from "@/components/headTitle/index";
export default {
  name: "introduce",
  mounted() {

  },
  components:{
    // headTitle,
  },
  data() {
    return {
    };
  },
   props: {
    isMobile: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
  },
};
</script>

<style scoped lang="scss">
#introduce {
  padding: 0 11vw 0 10vw;
}
.introduce_content {
  display: flex;
  div:nth-child(1){
      flex: 2;
      z-index:20;
      img {
      width: 100%;
    }
  }
  div:nth-child(2) {
    flex: 1;
    display: flex;
    align-items: flex-end;
    position: relative;
    div:nth-child(1){
        position: absolute;
        top: 0px;
        left: -11.1vw;
        font-weight: bold;
        color: rgb(242, 242, 242);
        z-index: 2;
    }
    div:nth-child(2){
        height: 50%;
        font-size: 0.9vw;
        padding: 1vh 1vw;
        display: block;
        p{
            text-indent:32px;
        }
    }
  }
}
p{
    margin:0;
}
.environment{
  display:flex;
  padding:0 5vw;
  flex-direction:row;
  justify-content: space-between;
  flex-wrap:wrap;
  div{
    width:30%;
    padding: 2vh 1vw;
    img{
      width: 100%;
    }
  }
}
</style>
