<!--荣誉资质-->
<template>
    <div id="honor">
        <div v-if="!isMobile">
            <div class="honor_list">
                <div>
                    <img src="../../../assets/images/certificate1.png" alt="" />
                </div>
                <div>
                    <img src="../../../assets/images/certificate2.png" alt="" />
                </div>
                <div>
                    <img src="../../../assets/images/certificate3.png" alt="" />
                </div>
                <div>
                    <img src="../../../assets/images/certificate4.png" alt="" />
                </div>
            </div>
            <div class="_honor_list">
                <div>
                    <img src="../../../assets/images/certificate5.png" alt="" />
                </div>
                <div>
                    <img src="../../../assets/images/certificate6.png" alt="" />
                </div>
                <div>
                    <img src="../../../assets/images/certificate7.png" alt="" />
                </div>
            </div>
        </div>
        <div v-else>
            <div class="honor_img">
                <div>
                    <img src="../../../assets/images/certificate1.png" alt="" />
                </div>
                <div>
                    <img src="../../../assets/images/certificate2.png" alt="" />
                </div>
                <div>
                    <img src="../../../assets/images/certificate3.png" alt="" />
                </div>
                <div>
                    <img src="../../../assets/images/certificate4.png" alt="" />
                </div>
                <div>
                    <img src="../../../assets/images/certificate5.png" alt="" />
                </div>
                <div>
                    <img src="../../../assets/images/certificate6.png" alt="" />
                </div>
            </div>
            <div class="_honor_img">
                <div>
                    <img src="../../../assets/images/certificate7.png" alt="" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  name: "honor",
  mounted() {
  },
  data() {
    return {
        isMobile:false
    };
  },
  methods: {
  }
};
</script>

<style scoped lang="scss">
.honor_list{
    padding: 0 11vw 0 10vw;
    display:flex;
    flex-direction:row;
    justify-content: space-between;
    flex-wrap:wrap;
    div{
        width:25%;
        img{
            width:100%;
        }
    }
}
._honor_list{
    padding: 0 11vw 0 10vw;
    display:flex;
    flex-direction:row;
    justify-content: space-between;
    flex-wrap:wrap;
    div{
        width:25%;
        img{
            width:100%;
        }
    }
    div:nth-child(3){
        width:50%;
    }
}
.honor_img{
    padding:2vh 5vw;
    display:flex;
    flex-direction:row;
    justify-content: space-between;
    flex-wrap:wrap;
     div{
        width:46%;
        padding:1vh 1vw;
        img{
            width:100%;
        }
    }
}
._honor_img{
    padding:2vh 5vw;
    img{
        width:100%;
    }
}
</style>